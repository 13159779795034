import { isString } from 'lodash/fp'

import { formatMoney } from './commonUtils'

export const PRODUCT_STATUS_ACTIVE = 'active'
export const PRODUCT_STATUS_INACTIVE = 'inactive'
export const PRODUCT_STATUS_AWAITING_APPROVAL = 'awaiting_approval'
export const PRODUCT_STATUS_APPROVED = 'approved'
export const PRODUCT_STATUS_REJECTED = 'rejected'

export const PRODUCT_STATUS_STATUS_REQUEST = [
  PRODUCT_STATUS_AWAITING_APPROVAL,
  PRODUCT_STATUS_APPROVED,
  PRODUCT_STATUS_REJECTED,
]
export const PRODUCT_IS_ACTIVE = [
  PRODUCT_STATUS_ACTIVE,
  PRODUCT_STATUS_INACTIVE,
]

export const PRODUCT_STATUS_COLOR_MAPPING = {
  [PRODUCT_STATUS_ACTIVE]: 'blue',
  [PRODUCT_STATUS_INACTIVE]: '',
  [PRODUCT_STATUS_AWAITING_APPROVAL]: 'blue',
  [PRODUCT_STATUS_APPROVED]: 'green',
  [PRODUCT_STATUS_REJECTED]: '',
}

export const convertLabelFilter = (
  valueFrom: number,
  valueTo: number,
  unit: string,
  label?: string
) => {
  if (unit === 'đ') {
    return isString(valueFrom) || isString(valueTo)
      ? `${formatMoney(valueFrom ?? 0, false)} ${unit} ~ ${formatMoney(
          valueTo ?? 0,
          false
        )} ${unit}`
      : null
  }
  return isString(valueFrom) || isString(valueTo)
    ? `${label} ${valueFrom}~${valueTo ?? 0} ${unit}`
    : null
}
export const KEY_FILTER_PRODUCT_RANGE = {
  salePriceRange: ['salePriceFrom', 'salePriceTo'],
  poPriceRange: ['poPriceFrom', 'poPriceTo'],
  lengthRange: ['lengthFrom', 'lengthTo'],
  widthRange: ['widthFrom', 'widthTo'],
  heightRange: ['heightFrom', 'heightTo'],
  weightRange: ['weightFrom', 'weightTo'],
}

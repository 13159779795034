export const DISTRIBUTOR_PURCHASE_ORDER_ALL = 'all'
export const DISTRIBUTOR_PURCHASE_ORDER_CREATED = 'created'
export const DISTRIBUTOR_PURCHASE_ORDER_AWAITING_PAYMENT = 'awaiting_payment'
export const DISTRIBUTOR_PURCHASE_ORDER_PAID = 'paid'
export const DISTRIBUTOR_PURCHASE_ORDER_CLOSED = 'closed'
export const DISTRIBUTOR_PURCHASE_ORDER_CANCELLED = 'cancelled'

export const DISTRIBUTOR_PURCHASE_ORDER_TAB_MAPPING = {
  [DISTRIBUTOR_PURCHASE_ORDER_ALL]: 'ALL',
  [DISTRIBUTOR_PURCHASE_ORDER_CREATED]: 'CREATED',
  [DISTRIBUTOR_PURCHASE_ORDER_AWAITING_PAYMENT]: 'AWAITING PAYMENT',
  [DISTRIBUTOR_PURCHASE_ORDER_PAID]: 'PAID',
  [DISTRIBUTOR_PURCHASE_ORDER_CLOSED]: 'CLOSED',
  [DISTRIBUTOR_PURCHASE_ORDER_CANCELLED]: 'CANCELLED',
}

export const DISTRIBUTOR_PURCHASE_ORDER_STATUS_SELECT = {
  [DISTRIBUTOR_PURCHASE_ORDER_CREATED]: 'CREATED',
  [DISTRIBUTOR_PURCHASE_ORDER_AWAITING_PAYMENT]: 'AWAITING PAYMENT',
  [DISTRIBUTOR_PURCHASE_ORDER_PAID]: 'PAID',
  [DISTRIBUTOR_PURCHASE_ORDER_CLOSED]: 'CLOSED',
  [DISTRIBUTOR_PURCHASE_ORDER_CANCELLED]: 'CANCELLED',
}

export const STATUS_ALLOW_CANCEL = [
  DISTRIBUTOR_PURCHASE_ORDER_CREATED,
  DISTRIBUTOR_PURCHASE_ORDER_AWAITING_PAYMENT,
  DISTRIBUTOR_PURCHASE_ORDER_PAID,
]

export const DISTRIBUTOR_PURCHASE_ORDER_STATUS_COLOR_MAPPING = {
  [DISTRIBUTOR_PURCHASE_ORDER_CREATED]: 'blue',
  [DISTRIBUTOR_PURCHASE_ORDER_AWAITING_PAYMENT]: 'warning',
  [DISTRIBUTOR_PURCHASE_ORDER_PAID]: 'green',
  [DISTRIBUTOR_PURCHASE_ORDER_CLOSED]: 'default',
  [DISTRIBUTOR_PURCHASE_ORDER_CANCELLED]: 'red',
}

export const TASK_VI = {
  tasksList: 'Danh sách xử lý',
  upload: 'Tải tập tin lên hệ thống',
  refresh: 'Làm mới',
  taskUpload: 'Xử lý tập tin',
  documentType: 'Dạng tin mẫu',
  selectDocumentType: 'Chọn tập tin mẫu',
  downloadTemplate: 'Tải tin mẫu',
  fileUpload: 'Tải tập tin',
  chooseFile: 'Chọn tập tin',
  submit: 'Tải lên',
  cancel: 'Hủy',
  'inbound-upload': 'Nhập kho',
  'pre-order-export': 'Pre Order Export',
  'package-status-upload': 'Trạng thái kiện hàng',
  'outlet-set-credit-upload': 'Cài đặt dư nợ khả dụng cho cửa hàng',
  'outlet-credit-collection-upload': 'Dư nợ theo lựa chọn',
  'package-collection-method-upload': 'Phương thức thu tiền cho kiện hàng',
  'inventory-override-upload': 'Ghi đè tồn kho',
  'buyin-export': 'Dữ liệu mua vào',
  'sellout-export': 'Dữ liệu bán ra',
  'package-export': 'Dữ liệu kiện hàng',
  'outlet-set-credit-limit-upload':
    'Cài đặt giới hạn dư nợ khả dụng cho cửa hàng',
  'product-trading-export': 'Dữ liệu sản phẩm niêm yết trên sàn giao dịch',
  'outlet-need-to-set-credit-export': 'Xuất dữ liệu cần set dư nợ cho cửa hàng',
  'outlet-credit-report-export': 'Xuất dữ liệu dư nợ cho cửa hàng',
  'outlet-credit-transaction-export': 'Xuất dữ liệu giao dịch dư nợ cửa hàng',
  'outlet-outstanding-transaction-export':
    'Xuất dữ liệu giao dịch dư nợ nổi bật cửa hàng',
  'bi-supplier-sku-mapping-upload': 'Liên kết hóa đơn nhà cung cấp',

  'outlet-credit-outstanding-transaction-export':
    'Xuất dữ liệu giao dịch dư nợ nổi bật cửa hàng',
  'inventory-recommendation-config-upload': 'Thiết lập nguồn hàng đề nghị',
  'product-trading-upload': 'Sản phẩm niêm yết trên giao dịch',
  'inventory-override-awaiting-for-sale-upload': 'Ghi đè tồn kho chờ bán',
  'auto-procurement-config-upload': 'Cấu hình tự động mua hàng',
  'pre-order-config-upload': 'Sản phẩm đặt hàng trước',
  'purchase-order-massive-create-po-upload': 'Đơn đặt hàng',
  'product-tax-upload': 'Thuế sản phẩm',
  'goods-not-focus-config-upload': 'Hàng khồng tập trung',
  'subsidy-rule-upload': 'Tài trợ giá',
  'product-listing-upload': 'Sản phẩm niêm yết',
  'distributor-contract-fee-upload': 'Phí hợp đồng Nhà phân phối',
  'cm-target-config-upload': 'Cài đặt CM3 mục tiêu',
  manualSubsidy: 'Trợ giá thủ công',
  fileName: 'Tên tập tin',
  status: 'Trạng thái xử lý',
  completed: 'Hoàn thành',
  in_progress: 'Đang xử lý',
  'in-progress': 'Đang xử lý',
  failed: 'Thất bại',
  type: 'Dạng tập tin mẫu',
  'inventory-export': 'Xuất dữ liệu tồn kho',
  createdDate: 'Ngày tạo',
  startDate: 'Ngày bắt đầu',
  endDate: 'Ngày kết thúc',
  search: 'Tìm kiếm',
  reset: 'Cài lại',
  updatedAt: 'Thời gian cập nhật',
  action: 'Tải tập tin về máy',
  createdAt: 'Ngày tạo',
  downloadOriginalFile: 'Tải về tập tin gốc',
  downloadErrorFile: 'Tải xuống tập tin lỗi',
  inventoryRecommend: 'Thiết lập nguồn hàng đề nghị',
  bulkCreateProductTradingByListing:
    'Tạo hàng loạt sản phẩm niêm yết trên giao dịch',
  productPreOrder: 'Sản phẩm đặt trước',
  massiveCreatePurchaseOrder: 'Đơn đặt hàng',
  taxManagement: 'Quản lý thuế VAT',
  supplierInvoiceProductMapping: 'Liên kết hóa đơn nhà cung cấp',
  uploadSuccess: 'Tải tập tin lên hệ thống thành công!',
  uploadFailed: 'Tải tập tin lên hệ thống thất bại!',
  bulkCreateProductListing: 'Tạo hàng loạt sản phẩm niêm yết',
  contractFeeManager: 'Phí hợp đồng Nhà phân phối',
  cm3Target: 'Cài đặt CM3 mục tiêu',
}

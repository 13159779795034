import { DISTRIBUTOR_PURCHASE_ORDER_TAB_MAPPING } from 'utils/distributorPurchaseOrder'

export const DISTRIBUTOR_PURCHASE_ORDER_EN = {
  purchaseOrder: 'Purchase Order',
  vigoQuotationNumber: 'Vigo Quotation Number',
  detail: 'Detail',
  statusHistory: 'Status History',
  no: 'No.',
  createdDate: 'Created Date',
  updatedDate: 'Updated Date',
  updateBy: 'Update By',
  relatedDocuments: 'Related Documents',
  deliveryTo: 'Delivery To',
  quotationDetails: 'Quotation Details',
  shipment: 'Shipment',
  deliveredBy: 'Delivered By',
  vigoQuotation: 'Vigo Quotation',
  noOfItem: 'No. of item',
  totalAmount: 'Total Amount',
  image: 'Image',
  name: 'Name',
  from: 'From',
  to: 'To',
  selectWarehouse: 'Select Warehouse',
  actualOfferPrice: 'Actual Offer Price',
  vigoOfferPrice: 'Vigo Offer Price',
  requestedQuantity: 'Requested Quantity',
  actualTotalAmount: 'Actual Total Amount',
  totalPriceDifferent: 'Total Price Different',
  packType: 'Pack Type',
  estimatedWeight: 'Estimated Weight',
  confirm: 'confirm',
  warehouse: 'Warehouse',
  distributor: 'Distributor',
  creationTime: 'Creation Time',
  startDate: 'Start Date',
  endDate: 'End Date',
  search: 'Search',
  reset: 'Reset',
  refresh: 'Refresh',
  currentStatus: 'Current Status',
  requestDate: 'Request Date',
  confirmDeadline: 'Confirm Deadline',
  totalValue: 'Total Value',
  status: 'Status',
  pre: 'Pre',
  next: 'Next',
  purchaseOrderNumber: 'Purchase Order Number',
  purchaseDetails: 'Purchase Details',
  purchasedByFrom: 'Purchased By (From)',
  supplierTo: 'Supplier (To)',
  nationalDrugCode: 'National Drug Code',
  batchNumber: 'Batch Number',
  shelfLifeMonth: 'Shelf Life (Month)',
  manufacturingDate: 'Manufacturing Date',
  expirationDate: 'Expiration Date',
  quantity: 'Quantity',
  ...DISTRIBUTOR_PURCHASE_ORDER_TAB_MAPPING,
}

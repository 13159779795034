export const PAYMENT_REQUEST_ALL = 'all'
export const PAYMENT_REQUEST_CREATED = 'created'
export const PAYMENT_REQUEST_PAID = 'paid'
export const PAYMENT_REQUEST_CANCELLED = 'cancelled'
export const PAYMENT_REQUEST_VIGO = 'vigo'
export const PAYMENT_REQUEST_DISTRIBUTOR = 'distributor'
export const PAYMENT_REQUEST_SUPPLIER = 'supplier'

export const ENTITY_TYPE_DISTRIBUTOR_PO = 'distributor_purchase_order'
export const ENTITY_TYPE_VIGO_PO = 'vigo_purchase_order'
export const ENTITY_TYPE_PURCHASE_ORDER = 'purchase_order'
export const ENTITY_TYPE_INBOUND = 'inbound'

export const PAYMENT_REQUEST_TAB_MAPPING = {
  [PAYMENT_REQUEST_ALL]: 'ALL',
  [PAYMENT_REQUEST_CREATED]: 'CREATED',
  [PAYMENT_REQUEST_PAID]: 'PAID',
  [PAYMENT_REQUEST_CANCELLED]: 'CANCELLED',
}

export const PAYMENT_REQUEST_STATUS_SELECT = {
  [PAYMENT_REQUEST_CREATED]: 'CREATED',
  [PAYMENT_REQUEST_PAID]: 'PAID',
  [PAYMENT_REQUEST_CANCELLED]: 'CANCELLED',
}

export const PAYMENT_REQUEST_STATUS_COLOR_MAPPING = {
  [PAYMENT_REQUEST_CREATED]: 'blue',
  [PAYMENT_REQUEST_PAID]: 'green',
  [PAYMENT_REQUEST_CANCELLED]: 'red',
}

export const PAYMENT_REQUEST_FROM_SELECT = {
  [PAYMENT_REQUEST_VIGO]: 'Vigo',
  [PAYMENT_REQUEST_DISTRIBUTOR]: 'Distributor',
}

export const PAYMENT_REQUEST_TO_SELECT = {
  [PAYMENT_REQUEST_VIGO]: 'Vigo',
  [PAYMENT_REQUEST_SUPPLIER]: 'Supplier',
}

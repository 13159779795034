import axios from 'axios'
import getConfig from 'next/config'
import { stringify } from 'qs'
import { useEffect, useState } from 'react'
import {
  defaultStringifyOption,
  getResponseData,
  isIndia,
} from 'utils/commonUtils'

const {
  publicRuntimeConfig: { adminPortalUrl },
} = getConfig()

export interface IProvince {
  id: number
  name: string
}

const useGetLocations = () => {
  const [locations, setLocations] = useState<any[]>([])
  const [isFinish, setIsFinish] = useState<boolean>(false)

  useEffect(() => {
    let isMounted = true
    const getLocations = async () => {
      try {
        const params = stringify(
          {
            level: 2,
          },
          defaultStringifyOption
        )
        const response = await axios.get(
          `${adminPortalUrl}/v1/location-info/list/level?${params}`
        )
        const data = getResponseData<any[]>(response)
        const nextData = isIndia
          ? data?.map((i) => {
              return { ...i, locationId: i?.locationId.toString() }
            })
          : data
        setLocations(nextData)
      } catch (error) {
        setLocations([])
      } finally {
        setIsFinish(true)
      }
    }

    isMounted && getLocations()
    return () => {
      isMounted = false
    }
  }, [])

  return { locations, isFinish }
}

export default useGetLocations

export const TYPE_OF_BUSINESS_SELECT = [
  'Hộ kinh doanh',
  'Công ty TNHH',
  'Công ty cổ phần',
  'Doanh nghiệp tư nhân',
  'Công ty hợp doanh',
]

export const TYPE_OF_BUSINESS_MODEL_SELECT = [
  'Tổng hợp',
  'Nhà phân phối nhãn chuyên trách',
]

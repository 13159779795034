import {
  CLAIMS_MANAGEMENT,
  DELIVERY_MANAGEMENT,
  INBOUND_LIST,
  ORDER_LIST,
  REPORT,
} from 'common/config/routes'

export const UPDATE_USER = 'update_user'
export const UPDATE_PASSWORD = 'update_password'
export const WAREHOUSE_OWNERSHIP = 'warehouse_ownership'
export const LEVEL_2_APPROVAL = 'level_2_approval'
export const UPDATE_PROFILE = 'update_profile'
export const UPDATE_E_INVOICE = 'update_e_invoice'
export const UPDATE_ACCESS_MIS = 'access_mis'
export const BRAND_GROUP = 'brand_group'

export const ENUM_INBOUND = 'inbound_management'
export const ENUM_ORDER = 'order_management'
export const ENUM_DELIVERY = 'delivery_management'
export const ENUM_REPORT = 'report_management'
export const ENUM_CLAIM = 'claim_management'

export const UPDATE_USER_SELECT_VN = {
  [UPDATE_USER]: 'Update User',
  [UPDATE_PASSWORD]: 'Update Password',
  [BRAND_GROUP]: 'Brand Group',
  [WAREHOUSE_OWNERSHIP]: 'Warehouse Ownership',
  [LEVEL_2_APPROVAL]: 'Level 2 Approval',
}
export const UPDATE_USER_SELECT_IN = {
  [UPDATE_USER]: 'Update User',
  [UPDATE_PASSWORD]: 'Update Password',
  [BRAND_GROUP]: 'Brand Group',
  [WAREHOUSE_OWNERSHIP]: 'Warehouse Ownership',
}

export const UPDATE_SETTING_TAB = {
  [UPDATE_E_INVOICE]: 'E-Invoice & E-Way-Bill',
  [UPDATE_ACCESS_MIS]: 'Access for MIS',
}

export const UPDATE_SETTING_SELECT_ACCESS_MIS = {
  [ENUM_INBOUND]: 'Inbound Management',
  [ENUM_ORDER]: 'Order Management',
  [ENUM_DELIVERY]: 'Delivery Management',
  [ENUM_REPORT]: 'Report Management',
  [ENUM_CLAIM]: 'Claim Management',
}

export const ACCESS_DENIED_MIS_SCREEN = {
  [ENUM_INBOUND]: INBOUND_LIST,
  [ENUM_ORDER]: ORDER_LIST,
  [ENUM_DELIVERY]: DELIVERY_MANAGEMENT,
  [ENUM_CLAIM]: CLAIMS_MANAGEMENT,
  [ENUM_REPORT]: REPORT,
}
export const ACCESS_DENIED_SCREEN = [
  INBOUND_LIST,
  ORDER_LIST,
  DELIVERY_MANAGEMENT,
  CLAIMS_MANAGEMENT,
  REPORT,
]

import { ArrowLeftOutlined } from '@ant-design/icons'
import {
  Button,
  Checkbox,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Select,
  Spin,
  Steps,
} from 'antd'
import ErrorAlert from 'components/ErrorAlert'
import LocaleSwitcher from 'components/LocaleSwitcher'
import LocationSelect from 'components/LocationSelect'
import BrandsSelect from 'containers/MasterData/Product/BrandsSelect'
import PlatformCategorySelect from 'containers/MasterData/Product/PlatformCategorySelect'
import Head from 'next/head'
import router from 'next/router'
import { getSession } from 'next-auth/react'
import { useTranslations } from 'next-intl'
import React, { useState } from 'react'
import axiosInstance from 'utils/axiosInstance'
import { getResponseErrorMessage } from 'utils/commonUtils'
import {
  TYPE_OF_BUSINESS_MODEL_SELECT,
  TYPE_OF_BUSINESS_SELECT,
} from 'utils/signUp'

import TermAndConditionModal from './termAndConditionModal'

const documentIdMapping = {
  [0]: 'distributor-info',
  [1]: 'business-info',
  [2]: 'infrastructure-info',
}
interface IProps {
  setOpenModal: (openModal: boolean) => void
}

export const LoginForm: React.FC<IProps> = ({ setOpenModal }) => {
  const [form] = Form.useForm()
  const [error, setError] = useState('')
  const [current, setCurrent] = useState(0)
  const [addressDistributor, setAddressDistributor] = useState(null)
  const [distributorAreas, setDistributorAreas] = useState(null)
  const translate = useTranslations()
  const [loading, setLoading] = useState(false)
  const [showTermAndConditionModal, setShowTermAndConditionModal] =
    useState(false)
  const [checked, setChecked] = useState(false)

  const onChange = (step: number) => {
    setCurrent(step)

    return document
      .getElementById(documentIdMapping[step])
      .scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  const handleSubmit = async (values) => {
    setLoading(true)
    setError('')
    try {
      const {
        detail,
        averageInventoryValue,
        brandIDs,
        categoryIDs,
        isCreditAllow,
        monthlySales,
        numberOfYearsOperation,
        businessName,
        email,
        ownerPhone,
        ownerName,
        type,
        numberOfAccountant,
        numberOfMotorBike,
        numberOfRider,
        numberOfSale,
        numberOfTruck,
        numberOfWarehouseStaff,
        numberWarehouse,
        businessModel,
        warehouseArea,
      } = values || {}
      const address = {
        ...addressDistributor,
        coordinate: {
          lat: 0,
          long: 0,
        },
        detail,
      }

      const businessActivities = {
        averageInventoryValue,
        brandIDs,
        businessModel,
        categoryIDs,
        isCreditAllow: isCreditAllow === 'yes',
        monthlySales,
        numberOfYearsOperation,
      }
      const distributionConfig = {
        distributionAddresses: distributorAreas,
      }

      const infrastructure = {
        numberOfAccountant,
        numberOfMotorBike,
        numberOfRider,
        numberOfSale,
        numberOfTruck,
        numberOfWarehouseStaff,
        numberWarehouse,
        warehouseArea,
      }

      const payload = {
        address,
        businessActivities,
        businessName,
        distributionConfig,
        email,
        infrastructure,
        ownerName,
        ownerPhone,
        type,
      }

      await axiosInstance.post(
        '/v1/distributor-registrations/registers',
        payload
      )
      setOpenModal(true)
      setLoading(false)
    } catch (err) {
      const error = getResponseErrorMessage(err)
      setError(
        typeof error === 'string'
          ? error
          : `${translate('common.createMessage.error', {
              name: translate('common.distributor'),
            })} ${error.map((e) => e.message).join(', ')}`
      )
      setLoading(false)
    }
  }
  const onChangeCheckbox = (e) => {
    setChecked(e.target.checked)
  }
  return (
    <div className="w-full sm:block md:flex">
      <div>
        <Steps
          progressDot
          current={current}
          direction="vertical"
          onChange={onChange}
          size="small"
          items={[
            {
              title: `1. ${translate('signUp.distributorInfo')}`,
            },
            {
              title: `2. ${translate('signUp.businessInfo')}`,
            },
            {
              title: `3. ${translate('signUp.infrastructureInfo')}`,
            },
          ]}
        />
      </div>
      <Form
        form={form}
        name="signUpForm"
        layout="vertical"
        className="w-full p-0"
        onFinish={handleSubmit}
      >
        <Spin spinning={loading}>
          <div className="w-full flex justify-between mb-8">
            <div className="text-lg font-medium">
              {translate('signUp.registerToSell')}
            </div>
            <div className="self-end">
              <LocaleSwitcher />
            </div>
          </div>
          <div>
            {error && (
              <div className="mb-4">
                <ErrorAlert error={error} onClose={() => setError('')} />
              </div>
            )}
          </div>
          <div className="overflow-y-auto h-[55vh]">
            {/* -------- DISTRIBUTOR INFORMATION --------*/}
            <div id="distributor-info">
              <div className="font-medium my-4">
                1. {translate('signUp.distributorInfo')}
              </div>
              <div className="flex justify-between">
                <Form.Item
                  name="businessName"
                  label={translate('signUp.businessName')}
                  className="inline-block w-[calc(50%-20px)] mr-2 "
                  rules={[
                    {
                      required: true,
                      message: translate('signUp.mandatory'),
                    },
                  ]}
                >
                  <Input
                    className="w-full "
                    placeholder={translate('signUp.businessName')}
                  />
                </Form.Item>

                <Form.Item
                  className="inline-block w-[calc(50%-20px)]"
                  label={translate('common.ownerName')}
                  name="ownerName"
                  rules={[
                    {
                      required: true,
                      message: translate('signUp.mandatory'),
                    },
                  ]}
                >
                  <Input
                    className="w-full"
                    placeholder={translate('common.ownerName')}
                  />
                </Form.Item>
              </div>
              <div className="flex justify-between">
                <Form.Item
                  name="ownerPhone"
                  label={translate('common.ownerPhone')}
                  className="inline-block w-[calc(50%-20px)] mr-2 "
                  rules={[
                    {
                      required: true,
                      message: translate('signUp.mandatory'),
                    },
                  ]}
                >
                  <Input
                    className="w-full "
                    placeholder={translate('common.ownerPhone')}
                  />
                </Form.Item>

                <Form.Item
                  className="inline-block w-[calc(50%-20px)]"
                  label="Email"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: translate('signUp.mandatory'),
                    },
                    {
                      type: 'email',
                      message: translate('common.validateEmail'),
                    },
                  ]}
                >
                  <Input className="w-full" placeholder="Email" />
                </Form.Item>
              </div>
              <Form.Item
                name="type"
                label={translate('signUp.typeOfBusiness')}
                rules={[
                  {
                    required: true,
                    message: translate('common.requiredSelect', {
                      name: translate('signUp.typeOfBusiness'),
                    }),
                  },
                ]}
              >
                <Select placeholder={translate('signUp.typeOfBusiness')}>
                  {TYPE_OF_BUSINESS_SELECT.map((value) => (
                    <Select.Option value={value} key={value}>
                      {value}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                name="address"
                label={translate('common.address')}
                rules={[
                  {
                    required: true,
                    message: translate('signUp.mandatory'),
                  },
                ]}
                getValueFromEvent={(value: any, selectedOptions) => {
                  if (!value?.[0] || !value?.[1] || !value?.[2]) return null
                  const payload = {
                    provinceID: selectedOptions[0]?.id,
                    province: selectedOptions[0]?.label,
                    districtID: selectedOptions[1]?.id,
                    district: selectedOptions[1]?.label,
                    wardID: selectedOptions[2]?.id,
                    ward: selectedOptions[2]?.label,
                  }
                  selectedOptions?.length === 3
                    ? setAddressDistributor(payload)
                    : setAddressDistributor(undefined)
                  return value
                }}
              >
                <LocationSelect
                  fromLevel={2}
                  toLevel={4}
                  placeholder={translate('signUp.placeholderAddress')}
                />
              </Form.Item>
              <Form.Item
                name="detail"
                rules={[
                  {
                    required: true,
                    message: translate('signUp.mandatory'),
                  },
                ]}
              >
                <Input
                  placeholder={translate('signUp.placeholderAddressDetail')}
                />
              </Form.Item>
              <Form.Item
                name="distributionAddresses"
                label={translate('signUp.distributionArea')}
                rules={[
                  {
                    required: true,
                    message: translate('signUp.mandatory'),
                  },
                ]}
                getValueFromEvent={(value: any, selectedOptions) => {
                  const data = selectedOptions?.reduce((prev, curr) => {
                    return curr?.length === 3
                      ? [
                          ...prev,
                          {
                            district: curr[1].label,
                            districtID: curr[1].value,
                            province: curr[0].label,
                            provinceID: curr[0].value,
                            ward: curr[2].label,
                            wardID: curr[2].value,
                          },
                        ]
                      : prev
                  }, [])

                  setDistributorAreas(data)
                  return value?.reduce(
                    (prev, curr) =>
                      curr?.length === 3 ? [...prev, curr] : prev,
                    []
                  )
                }}
              >
                <LocationSelect
                  placeholder={translate('common.requiredSelect', {
                    name: translate('common.area'),
                  })}
                  multiple
                  fromLevel={2}
                  toLevel={4}
                />
              </Form.Item>
            </div>
            {/* -------- BUSINESS INFORMATION --------*/}
            <div id="business-info">
              <div className="font-medium my-4">
                2. {translate('signUp.businessInfo')}
              </div>
              <Form.Item
                name="businessModel"
                label={translate('signUp.businessModel')}
                rules={[
                  {
                    required: true,
                    message: translate('signUp.mandatory'),
                  },
                ]}
              >
                <Select
                  placeholder={translate('signUp.placeholderOneOrMore')}
                  mode="multiple"
                >
                  {TYPE_OF_BUSINESS_MODEL_SELECT.map((value) => (
                    <Select.Option value={value} key={value}>
                      {value}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                name="categoryIDs"
                label={translate('signUp.category')}
                rules={[
                  {
                    required: true,
                    message: translate('signUp.mandatory'),
                  },
                ]}
              >
                <PlatformCategorySelect
                  multiple
                  placeholder={translate('common.requiredSelect', {
                    name: translate('distributorProfile.category'),
                  })}
                />
              </Form.Item>

              <div className="flex justify-between">
                <Form.Item
                  name="numberOfYearsOperation"
                  label={translate('signUp.numberOfYears')}
                  className="inline-block w-[calc(50%-20px)] mr-2"
                  rules={[
                    {
                      required: true,
                      message: translate('signUp.mandatory'),
                    },
                  ]}
                >
                  <InputNumber
                    min={0}
                    className="w-full"
                    placeholder={translate('common.requiredInput', {
                      name: translate('signUp.numberRequired'),
                    })}
                  />
                </Form.Item>

                <Form.Item
                  className="inline-block w-[calc(50%-20px)]"
                  label={translate('signUp.monthlySales')}
                  name="monthlySales"
                  rules={[
                    {
                      required: true,
                      message: translate('signUp.mandatory'),
                    },
                  ]}
                >
                  <InputNumber
                    min={0}
                    className="w-full"
                    placeholder={translate('common.requiredInput', {
                      name: translate('signUp.numberRequired'),
                    })}
                    addonAfter="₫"
                  />
                </Form.Item>
              </div>

              <div className="flex justify-between">
                <Form.Item
                  name="isCreditAllow"
                  label={translate('signUp.creditAllow')}
                  className="inline-block w-[calc(50%-20px)] mr-2"
                  initialValue={'yes'}
                  rules={[
                    {
                      required: true,
                      message: translate('signUp.mandatory'),
                    },
                  ]}
                >
                  <Radio.Group defaultValue={'yes'}>
                    <Radio value="yes">
                      {translate('common.invoice_updated_yes')}
                    </Radio>
                    <Radio value="no">
                      {translate('common.invoice_updated_no')}
                    </Radio>
                  </Radio.Group>
                </Form.Item>

                <Form.Item
                  className="inline-block w-[calc(50%-20px)]"
                  label={translate('signUp.averageInventoryValue')}
                  name="averageInventoryValue"
                  rules={[
                    {
                      required: true,
                      message: translate('signUp.mandatory'),
                    },
                  ]}
                >
                  <InputNumber
                    min={0}
                    className="w-full"
                    placeholder={translate('common.requiredInput', {
                      name: translate('signUp.numberRequired'),
                    })}
                    addonAfter="₫"
                  />
                </Form.Item>
              </div>

              <Form.Item
                label={translate('distributorProfile.brandDistributor')}
                name="brandIDs"
                rules={[
                  {
                    required: true,
                    message: translate('signUp.mandatory'),
                  },
                ]}
              >
                <BrandsSelect
                  mode="multiple"
                  placeholder={translate('common.requiredSelect', {
                    name: translate('common.brand'),
                  })}
                />
              </Form.Item>
            </div>
            {/* -------- INFRASTRUCTURE INFORMATION --------*/}
            <div id="infrastructure-info">
              <div className="font-medium my-4">
                3. {translate('signUp.infrastructureInfo')}
              </div>
              <div className="flex justify-between">
                <Form.Item
                  name="numberWarehouse"
                  label={translate('signUp.numberWarehouse')}
                  className="inline-block w-[calc(50%-20px)] mr-2"
                  rules={[
                    {
                      required: true,
                      message: translate('signUp.mandatory'),
                    },
                  ]}
                >
                  <InputNumber
                    min={0}
                    className="w-full"
                    placeholder={translate('common.requiredInput', {
                      name: translate('signUp.numberRequired'),
                    })}
                  />
                </Form.Item>

                <Form.Item
                  className="inline-block w-[calc(50%-20px)]"
                  label={translate('signUp.totalWarehouseArea')}
                  name="warehouseArea"
                  rules={[
                    {
                      required: true,
                      message: translate('signUp.mandatory'),
                    },
                  ]}
                >
                  <InputNumber
                    min={0}
                    className="w-full"
                    placeholder={translate('common.requiredInput', {
                      name: translate('signUp.numberRequired'),
                    })}
                    addonAfter="m²"
                  />
                </Form.Item>
              </div>

              <div className="flex justify-between">
                <Form.Item
                  name="numberOfSale"
                  label={translate('signUp.numberOfSales')}
                  className="inline-block w-[calc(50%-20px)] mr-2"
                  rules={[
                    {
                      required: true,
                      message: translate('signUp.mandatory'),
                    },
                  ]}
                >
                  <InputNumber
                    min={0}
                    className="w-full"
                    placeholder={translate('common.requiredInput', {
                      name: translate('signUp.numberRequired'),
                    })}
                  />
                </Form.Item>

                <Form.Item
                  className="inline-block w-[calc(50%-20px)]"
                  label={translate('signUp.numberAccountant')}
                  name="numberOfAccountant"
                  rules={[
                    {
                      required: true,
                      message: translate('signUp.mandatory'),
                    },
                  ]}
                >
                  <InputNumber
                    min={0}
                    className="w-full"
                    placeholder={translate('common.requiredInput', {
                      name: translate('signUp.numberRequired'),
                    })}
                  />
                </Form.Item>
              </div>

              <div className="flex justify-between">
                <Form.Item
                  name="numberOfWarehouseStaff"
                  label={translate('signUp.numberWarehouseStaff')}
                  className="inline-block w-[calc(50%-20px)] mr-2"
                  rules={[
                    {
                      required: true,
                      message: translate('signUp.mandatory'),
                    },
                  ]}
                >
                  <InputNumber
                    min={0}
                    className="w-full"
                    placeholder={translate('common.requiredInput', {
                      name: translate('signUp.numberRequired'),
                    })}
                  />
                </Form.Item>

                <Form.Item
                  className="inline-block w-[calc(50%-20px)]"
                  label={translate('signUp.numberRider')}
                  name="numberOfRider"
                  rules={[
                    {
                      required: true,
                      message: translate('signUp.mandatory'),
                    },
                  ]}
                >
                  <InputNumber
                    min={0}
                    className="w-full"
                    placeholder={translate('common.requiredInput', {
                      name: translate('signUp.numberRequired'),
                    })}
                  />
                </Form.Item>
              </div>

              <div className="flex justify-between">
                <Form.Item
                  name="numberOfTruck"
                  label={translate('signUp.numberTruck')}
                  className="inline-block w-[calc(50%-20px)] mr-2"
                  rules={[
                    {
                      required: true,
                      message: translate('signUp.mandatory'),
                    },
                  ]}
                >
                  <InputNumber
                    min={0}
                    className="w-full"
                    placeholder={translate('common.requiredInput', {
                      name: translate('signUp.numberRequired'),
                    })}
                  />
                </Form.Item>

                <Form.Item
                  className="inline-block w-[calc(50%-20px)]"
                  label={translate('signUp.numberMotorbike')}
                  name="numberOfMotorBike"
                  rules={[
                    {
                      required: true,
                      message: translate('signUp.mandatory'),
                    },
                  ]}
                >
                  <InputNumber
                    min={0}
                    className="w-full"
                    placeholder={translate('common.requiredInput', {
                      name: translate('signUp.numberRequired'),
                    })}
                  />
                </Form.Item>
              </div>
            </div>
          </div>
          <div className="mt-2">
            <Checkbox onChange={onChangeCheckbox}></Checkbox>
            <span>
              {' '}
              {translate.rich(`signUp.termAndCondition`, {
                link: (value) => (
                  <a onClick={() => setShowTermAndConditionModal(true)}>
                    {value}
                  </a>
                ),
              })}
            </span>
          </div>
          <Form.Item>
            <div className=" mt-4 w-full gap-x-6 sm:block md:flex">
              <div className="w-1/5">
                <Button
                  icon={<ArrowLeftOutlined />}
                  onClick={() => router.push('/')}
                >
                  {translate('signUp.back')}
                </Button>
              </div>
              <div className="w-4/5">
                <Button
                  htmlType="submit"
                  type="primary"
                  className="w-full"
                  disabled={!checked}
                >
                  {translate('signUp.confirmSubmit')}
                </Button>
              </div>
            </div>
          </Form.Item>
        </Spin>
      </Form>
      <TermAndConditionModal
        visible={showTermAndConditionModal}
        onCancel={() => setShowTermAndConditionModal(false)}
        translate={translate}
      />
    </div>
  )
}

export const SignUpSuccessModal: React.FC<{ openModal: boolean }> = ({
  openModal,
}) => {
  const translate = useTranslations()
  return (
    <Modal centered open={openModal} closable={false} footer={null}>
      <div className="w-full text-center">
        <div className="w-full flex justify-center items-center">
          <img className="w-[300px]" src="/waitingForSending.png" alt="vigo" />
        </div>
        <div>
          <div className="text-2xl">{translate('signUp.thankYouSubmit')}</div>
          <div>{translate('signUp.contact')}</div>
          <div className="mt-6">
            <Button
              icon={<ArrowLeftOutlined />}
              onClick={() => router.push('/')}
            >
              {translate('signUp.backToLogin')}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export const SignUpComponent = () => {
  const [openModal, setOpenModal] = useState(false)
  return (
    <>
      <Head>
        <title>Sign Up</title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Head>

      <div
        className="h-screen flex justify-center items-center "
        style={{
          backgroundImage: 'url("/Background.png")',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          minHeight: '100vh',
        }}
      >
        <div>
          <div className="w-full flex justify-center items-center mb-4">
            <img className="w-[150px]" src="/logoVigoLogin.png" alt="vigo" />
          </div>
          {openModal ? (
            <SignUpSuccessModal openModal={openModal} />
          ) : (
            <div className="w-full flex flex-col justify-center items-center px-5 pt-8 pb-0 max-w-full bg-white rounded-lg shadow-xl sm:max-w-[30rem] md:min-w-[50rem]">
              <LoginForm setOpenModal={setOpenModal} />
            </div>
          )}
        </div>
      </div>
    </>
  )
}

const SignUpPage = () => <SignUpComponent />

SignUpPage.getInitialProps = async (context) => {
  const { req, res } = context
  const session = await getSession({ req })
  if (session && res && session.accessToken) {
    res.writeHead(302, {
      Location: '/sign-up',
    })
    res.end()
    return
  }

  return {
    session: undefined,
  }
}
export default SignUpPage

import {
  PAYMENT_REQUEST_ALL,
  PAYMENT_REQUEST_CANCELLED,
  PAYMENT_REQUEST_CREATED,
  PAYMENT_REQUEST_PAID,
} from 'utils/paymentRequest'

const PAYMENT_REQUEST_STATUSES_VI = {
  [PAYMENT_REQUEST_ALL]: 'TẤT CẢ',
  [PAYMENT_REQUEST_CREATED]: 'MỚI',
  [PAYMENT_REQUEST_PAID]: 'ĐÃ THANH TOÁN',
  [PAYMENT_REQUEST_CANCELLED]: 'ĐÃ HỦY',
}

export const PAYMENT_REQUEST_VI = {
  vigoQuotationNumber: 'Mã báo giá Vigo',
  detail: 'Chi tiết báo giá',
  statusHistory: 'Lịch sử thay đổi',
  no: 'Thứ tự',
  createdDate: 'Ngày tạo',
  updatedDate: 'Ngày cập nhật',
  updateBy: 'Người cập nhật',
  relatedDocuments: 'Thông tin liên quan',
  deliveryTo: 'Vận chuyển đến',
  quotationDetails: 'Chi tiết báo giá',
  shipment: 'Tuyến hàng',
  vigoQuotation: 'Báo giá từ Vigo',
  deliveredBy: 'Giao hàng bởi',
  noOfItem: 'Số lượng',
  totalAmount: 'Tổng giá trị',
  image: 'Hình ảnh',
  name: 'Tên sản phẩm',
  cancelled: 'ĐÃ HỦY',
  paid: 'ĐÃ THANH TOÁN',
  actualOfferPrice: 'Giá đề nghị thực tế',
  selectWarehouse: 'Chọn kho hàng',
  vigoOfferPrice: 'Giá đề nghị từ Vigo',
  requestedQuantity: 'Số lượng yêu cầu',
  actualTotalAmount: 'Tổng giá trị thực tế',
  totalPriceDifferent: 'Tổng giá chênh lệch',
  packType: 'Đơn vị đóng gói',
  estimatedWeight: 'Trọng lượng ước tính',
  confirm: 'Xác nhận',
  warehouse: 'Kho hàng',
  distributor: 'Nhà phân phối',
  creationTime: 'Thời gian tạo',
  startDate: 'Ngày bắt đầu',
  endDate: 'Ngày kết thúc',
  search: 'Tìm kiếm',
  reset: 'Cài lại',
  refresh: 'Làm mới',
  currentStatus: 'Trạng thái hiện tại',
  requestDate: 'Ngày yêu cầu',
  confirmDeadline: 'Thời gian xác nhận',
  totalValue: 'Tổng giá trị',
  status: 'Trạng thái',
  prev: 'Trang trước',
  next: 'Trang kế tiếp',
  paymentRequestNumber: 'Mã yêu cầu than toán',
  relatedDocument: 'Tài liệu liên quan',
  paymentRequest: 'Yêu cầu thanh toán',
  paymentFrom: 'Thanh toán bởi',
  paymentTo: 'Thanh toán đến',
  partner: 'Đối tác',
  paymentDetails: 'Chi tiết thanh toán',
  noteFromVigoFinance: 'Ghi chú từ kế toán Vigo',
  distributorPO: 'Đơn mua hàng',
  bank: 'Ngân hàng',
  bankName: 'Tên chủ tài khoản',
  bankAccount: 'Tài khoản',
  transferNote: 'Ghi chú chuyển khoản',
  amount: 'Số tiền',
  ...PAYMENT_REQUEST_STATUSES_VI,
}

import { PAYMENT_REQUEST_TAB_MAPPING } from 'utils/paymentRequest'

export const PAYMENT_REQUEST_EN = {
  pageName: 'Purchase Request',
  refresh: 'Refresh',
  PRNumber: 'PR Number',
  warehouse: 'Warehouse',
  distributor: 'Distributor',
  brandGroup: 'Brand Group',
  requestDate: 'Request Date',
  currentStatus: 'Current Status',
  startDate: 'Start Date',
  endDate: 'End Date',
  search: 'Search',
  reset: 'Reset',
  copyPurchaseRequest: 'Copy Purchase Request',
  copyExistingPR: 'Copy From Existing Purchase Request',
  selectWarehouse: 'Select Warehouse',
  createdDate: 'Created Date',
  createdBy: 'Created By',
  requestBy: 'Request By',
  leadTime: 'Lead Time',
  dayAgo: 'day ago',
  monthAgo: 'month ago',
  yearAgo: 'year ago',
  pre: 'Pre',
  next: 'Next',
  records: 'records',
  page: 'page',
  status: 'Status',
  items: 'Items',
  addItem: 'Add Item',
  selectSKUs: 'Select SKUs',
  selectCategory: 'Select Category',
  productName: 'Product Name',
  poPrice: 'PO Price',
  salePrice: 'Sale Price',
  image: 'Image',
  ok: 'OK',
  quantity: 'Quantity',
  quantityRequired: 'Quantity is Required!',
  packType: 'Pack Type',
  action: 'Action',
  cancel: 'Cancel',
  create: 'Create',
  name: 'Name',
  brandGroupRequired: 'Please select brand group!',
  brand: 'Brand',
  update: 'Update',
  statusHistory: 'Status History',
  paymentRequestNumber: 'Payment Request Number',
  relatedDocument: 'Related Document',
  paymentRequest: 'Payment Request',
  paymentFrom: 'Payment From',
  paymentTo: 'Payment To',
  partner: 'Partner',
  paymentDetails: 'Payment Details',
  noteFromVigoFinance: 'Note From Vigo Finance',
  distributorPO: 'Distributor PO',
  creationTime: 'Creation Time',
  totalValue: 'Total Value',
  relatedDocuments: 'Related Documents',
  bank: 'Bank',
  bankName: 'Bank Name',
  bankAccount: 'Bank Account',
  transferNote: 'Transfer Note',
  amount: 'Amount',
  ...PAYMENT_REQUEST_TAB_MAPPING,
}

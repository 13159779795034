import { ITradeOrderItemCustom } from 'containers/Fulfillment/TradeOrder/Detail/India/TradeOrderDetailTableIndia'
import { ITradeOrder, ITradeOrderItem } from 'interfaces/tradeOrder'
import { get } from 'lodash'

import { isIndia } from './commonUtils'

export const TRADE_ORDER_STATUS_CREATED = 'created'
export const TRADE_ORDER_STATUS_PROCESSING = 'processing'
export const TRADE_ORDER_STATUS_COMPLETED = 'completed'
export const TRADE_ORDER_STATUS_CANCELLED = 'cancelled'
export const TRADE_ORDER_STATUS_PENDING = 'pending'
export const TRADE_ORDER_STATUS_IN_DELIVERY = 'in_delivery'
export const TRADE_ORDER_STATUS_COMPLETED_DELIVERED = 'delivered'
export const TRADE_ORDER_STATUS_COMPLETED_PARTIAL = 'partial_delivered'
export const TRADE_ORDER_STATUS_FAILED = 'fail_delivered'
export const TRADE_ORDER_STATUS_ALL = 'all'

export const TRADE_ORDER_ITEM_TYPE_AVAILABLE = 'available'
export const TRADE_ORDER_ITEM_TYPE_PRE_ORDER = 'pre_order'

export const ENTITY_ORDER = 'order'
export const ENTITY_PRE_ORDER = 'pre_order'

export const ENTITY_TYPE_TRADE_ORDER = 'TRADE_ORDER'

export const CART_TOTAl = 'cart'

export const TODAY = 'today'
export const THIS_WEEK = 'this_week'
export const THIS_MONTH = 'this_month'
export const THIS_YEAR = 'this_year'
export const THIS_QUARTER = 'this_quarter'

export const TRADE_ORDER_STATUS_MAPPING = {
  [TRADE_ORDER_STATUS_ALL]: 'ALL',
  [TRADE_ORDER_STATUS_PENDING]: 'PENDING',
  [TRADE_ORDER_STATUS_PROCESSING]: 'PROCESSING',
  [TRADE_ORDER_STATUS_IN_DELIVERY]: 'IN DELIVERY',
  [TRADE_ORDER_STATUS_COMPLETED]: 'COMPLETED',
  [TRADE_ORDER_STATUS_FAILED]: 'FAILED',
  [TRADE_ORDER_STATUS_CANCELLED]: 'CANCELLED',
}

export const TRADE_ORDER_ITEM_COLOR_MAPPING = {
  [TRADE_ORDER_ITEM_TYPE_PRE_ORDER]: '#FFA500',
  [TRADE_ORDER_ITEM_TYPE_AVAILABLE]: '#108ee9',
}
export const TRADE_ORDER_ITEM_COLOR_TYPE_MAPPING = {
  [TRADE_ORDER_ITEM_TYPE_PRE_ORDER]: '#FFA500',
  [TRADE_ORDER_ITEM_TYPE_AVAILABLE]: '#108ee9',
}
export const TRADE_ORDER_ITEM_TEXT_TYPE_MAPPING = {
  [TRADE_ORDER_ITEM_TYPE_PRE_ORDER]: 'YES',
  [TRADE_ORDER_ITEM_TYPE_AVAILABLE]: 'NO',
}

export const ENTITY_GET_DATE = {
  [TODAY]: 'day',
  [THIS_WEEK]: 'week',
  [THIS_MONTH]: 'month',
  [THIS_QUARTER]: 'quarter',
  [THIS_YEAR]: 'year',
}

export const RENDER_BUTTON_SELECT_DATE = [
  TODAY,
  THIS_WEEK,
  THIS_MONTH,
  THIS_QUARTER,
  THIS_YEAR,
]

export const TRADE_ORDER_STATUS_SELECT = {
  [TRADE_ORDER_STATUS_CREATED]: 'CREATED',
  [TRADE_ORDER_STATUS_PROCESSING]: 'PROCESSING',
  [TRADE_ORDER_STATUS_COMPLETED]: 'COMPLETED',
  [TRADE_ORDER_STATUS_CANCELLED]: 'CANCELLED',
  [TRADE_ORDER_STATUS_PENDING]: 'PENDING',
}

export const TRADE_ORDER_STATUS_COLOR_MAPPING = {
  [TRADE_ORDER_STATUS_CREATED]: 'blue',
  [TRADE_ORDER_STATUS_PROCESSING]: 'warning',
  [TRADE_ORDER_STATUS_COMPLETED]: 'green',
  [TRADE_ORDER_STATUS_CANCELLED]: 'default',
  [TRADE_ORDER_STATUS_PENDING]: 'red',
  [TRADE_ORDER_STATUS_COMPLETED_DELIVERED]: 'green',
  [TRADE_ORDER_STATUS_COMPLETED_PARTIAL]: 'green',
  [TRADE_ORDER_STATUS_IN_DELIVERY]: 'geekblue',
  [TRADE_ORDER_STATUS_FAILED]: 'magenta',
}

export const TRADE_ORDER_STATUS_VN = [
  TRADE_ORDER_STATUS_PENDING,
  TRADE_ORDER_STATUS_PROCESSING,
  TRADE_ORDER_STATUS_IN_DELIVERY,
  TRADE_ORDER_STATUS_COMPLETED_DELIVERED,
  TRADE_ORDER_STATUS_COMPLETED_PARTIAL,
  TRADE_ORDER_STATUS_FAILED,
  TRADE_ORDER_STATUS_CANCELLED,
]
export const TRADE_ORDER_STATUS_IN = [
  TRADE_ORDER_STATUS_CREATED,
  TRADE_ORDER_STATUS_PROCESSING,
  TRADE_ORDER_STATUS_COMPLETED,
  TRADE_ORDER_STATUS_CANCELLED,
  TRADE_ORDER_STATUS_PENDING,
]

export const summaryDataTO = (tradeOrderDetail: ITradeOrder, key: string) =>
  tradeOrderDetail?.items?.reduce(
    (prev, curr) => prev + calculationTradeOrderItem(curr)[key],
    0
  )

export const calculationTradeOrderItem = (
  tradeOrderItem: ITradeOrderItem,
  isItemsDelivered?: boolean
) => {
  const netDeliveredQuantity = tradeOrderItem?.netDeliveredQuantity || 0

  const quantity = tradeOrderItem?.orderedQuantity

  const ratioQuantity = isItemsDelivered ? netDeliveredQuantity / quantity : 1

  //Unit Price from fulfillment order or pre-order india
  const price = tradeOrderItem?.originalPrice || 0

  const dynamicCommission =
    tradeOrderItem?.dynamicCommissionAmount * ratioQuantity || 0

  const cgstAmount = tradeOrderItem?.centralGstAmount * ratioQuantity || 0

  const sgstAmount = tradeOrderItem?.stateGstAmount * ratioQuantity || 0

  const cessAmount = tradeOrderItem?.cessAmount * ratioQuantity || 0

  const igstAmount = tradeOrderItem?.igstAmount * ratioQuantity || 0

  const totalPromo =
    tradeOrderItem?.promo?.reduce(
      (prev, curr) => prev + (curr?.amount || 0),
      0
    ) * ratioQuantity
  //dms-2172 Gross Amount: SUM of (Unit Price * Quantity in Pieces for all the SKUS)
  const grossAmount = price * quantity * ratioQuantity
  //Distributor Subsidy = (auto + manual) =  0 india (DMS-2182)
  const autoSubsidyAmount =
    tradeOrderItem?.distributorSubsidy?.autoSubsidyAmount * ratioQuantity || 0
  const manualSubsidyAmount =
    tradeOrderItem?.distributorSubsidy?.manualSubsidyAmount * ratioQuantity || 0
  const specialSubsidyAmount =
    tradeOrderItem?.distributorSubsidy?.specialSubsidyAmount * ratioQuantity ||
    0
  const distributorSubsidy =
    autoSubsidyAmount + manualSubsidyAmount + specialSubsidyAmount
  //Net Commission: Gross Commission - Agent Subsidy (DMS-2182)
  const agentPriceSubsidy =
    tradeOrderItem?.agentPriceSubsidy * ratioQuantity || 0
  const salesCommissionAmount =
    tradeOrderItem?.salesCommissionAmount * ratioQuantity || 0

  // TaxAbleValue = TaxAbleAmount  (UnitPrice * quantity - Total Promo) (DMS-2183) in india
  const taxAbleValue = grossAmount > 0 ? grossAmount - totalPromo : 0

  const taxValue = isIndia
    ? cgstAmount + sgstAmount + cessAmount + igstAmount
    : 0

  const netOrderedAmount = tradeOrderItem?.netOrderedAmount || 0
  const netDeliveredAmount = tradeOrderItem?.netDeliveredAmount || 0

  //Price (Per Qty) DMS-2324 in VN price - (Total Promo + Distributor Subsidy + Agent Subsidy) / Quantity

  //Same invoice amount in skus items TO (India) with dynamicCommission = 0, taxValue = 0 in Viet Nam
  return {
    distributorSubsidy,
    totalSubsidy: distributorSubsidy + agentPriceSubsidy,
    taxValue,
    grossAmount,
    taxAbleValue,
    cgstAmount,
    sgstAmount,
    cessAmount,
    igstAmount,
    totalPromo,
    itemOrderAmountOrdered: tradeOrderItem?.netOrderedAmount || 0,
    itemOrderAmountDelivered: tradeOrderItem?.netDeliveredAmount || 0,
    itemOrderAmountReturned: tradeOrderItem?.netReturnedAmount || 0,
    netOrderedAmount,
    salesCommissionAmount,
    dynamicCommission,
    agentSubsidy: agentPriceSubsidy,
    netDeliveredAmount,
  }
}
export const getTradeOrderItemsByStatus = (
  items: ITradeOrderItemCustom[],
  key: string,
  status: string
) => items?.filter((item) => get(item, key) === status)

export const getConfirmedQuantityOrder = (record: ITradeOrderItemCustom) => {
  const pack = record?.product?.pack || 1
  const quantity =
    record?.orders?.[0]?.items?.find(
      (item) => item?.productID === record?.productID
    )?.quantity || record?.orderedQuantity
  const quantityCase = Math.floor(quantity / pack) || 0
  const quantityPcs = quantity % pack || 0
  return { quantityCase, quantityPcs }
}
